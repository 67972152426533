import React from "react";

import { useTranslations } from "../usePage";

export const withTranslationInValue = <T,>(WrappedComponent: React.FC<T>) => {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component";

  const Wrapper: React.FC<Omit<T, "value"> & { textKey: string }> = (props) => {
    const t = useTranslations();

    const { textKey, ...rest } = props;

    return <WrappedComponent {...(rest as T)} value={t[textKey]} />;
  };

  Wrapper.displayName = `withTheme(${displayName})`;

  return Wrapper;
};
