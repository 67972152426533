import React, { useContext } from "react";
import { GQLImage } from "@src/components/GQLImage";
import { createContext, ReactNode } from "react";
import { TranslationContext } from "../translation/TranslationContext";
import { forEach } from "lodash";

export interface PageContextProps {
  translations: Record<string, string>;
  images: Record<string, GQLImage>;
}

export const PageContext = createContext<PageContextProps | null>(null);

export interface PageProviderProps {
  translations: Record<string, string>;
  images: Record<string, GQLImage>;
  children: ReactNode;
}

export const PageProvider = ({ translations, images, children }: PageProviderProps) => {
  return <PageContext.Provider value={{ translations, images }}>{children}</PageContext.Provider>;
};

export const useLoadTranslations = (values: Record<string, string>) => {
  const t = useContext(TranslationContext);

  forEach(values, (_v, k) => {
    values[k] = null;
  });

  forEach(values, (_v, k) => {
    const translation = t.translate(`directus.new_mentor.${k}`).toString();
    if (translation) values[k] = translation;
  });

  return values;
};

const fakeImage = () => "https://via.placeholder.com/150";

export const useLoadImages = (assets: Record<string, GQLImage>, values: string[]) => {
  const images: Record<string, GQLImage> = {};

  forEach(values, (k) => {
    if (!assets) return;
    const image = assets[k];
    if (image) images[k] = image;
    else images[k] = { publicURL: fakeImage() };
  });

  return images;
};
