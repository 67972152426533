import React from "react";
import { Image as OriginalImage, FlexBackground as OriginalFlexBackground } from "../extended";
import { useImages } from "../usePage";

const withImageInImg = <T,>(WrappedComponent: React.FC<T>) => {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component";

  const Wrapper: React.FC<Omit<T, "img"> & { imgKey: string }> = (props) => {
    const i = useImages();
    const { imgKey, ...rest } = props;

    return <WrappedComponent {...(rest as T)} img={i[imgKey]} />;
  };

  Wrapper.displayName = `withTheme(${displayName})`;

  return Wrapper;
};

export const Image = withImageInImg(OriginalImage);
export const FlexBackground = withImageInImg(OriginalFlexBackground);
