import React from "react";
import {
  Body as OriginalBody,
  Display as OriginalDisplay,
  Headline as OriginalHeadline,
  Label as OriginalLabel,
  TypographyWithValue as OriginalTypographyWithValue,
} from "../extended";
import { withTranslationInValue } from "./utils";

export const Label = withTranslationInValue(OriginalLabel);
export const Body = withTranslationInValue(OriginalBody);
export const Display = withTranslationInValue(OriginalDisplay);
export const Headline = withTranslationInValue(OriginalHeadline);
export const Typography = withTranslationInValue(OriginalTypographyWithValue);
